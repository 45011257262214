var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"box"},[_c('ul',{staticClass:"option-tab"},[_c('li',{staticClass:"option-item",class:{ 'in-use': _vm.applicationType === 'email' },on:{"click":function($event){return _vm.applicationTypeEmail()}}},[_vm._v(" Email ")]),_c('li',{staticClass:"option-item",class:{ 'in-use': _vm.applicationType === 'link' },on:{"click":function($event){return _vm.applicationTypeLink()}}},[_vm._v(" URL ")]),_c('li',{staticClass:"option-item",class:{ 'in-use': _vm.applicationType === 'hide' },on:{"click":function($event){return _vm.applicationTypeHide()}}},[_vm._v(" None ")])]),(_vm.applicationType === 'email')?_c('div',[_c('div',{staticClass:"tab-header"},[_c('h3',[_vm._v("Email to receive application")]),_c('p',[_vm._v("Following email will receive the application from candidates.")])]),_c('div',{staticClass:"field has-addons is-fullwidth"},[_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.application_email),expression:"application_email"}],staticClass:"input",attrs:{"type":"email","placeholder":"Enter an email"},domProps:{"value":(_vm.application_email)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.AddEmail()},"input":function($event){if($event.target.composing){ return; }_vm.application_email=$event.target.value}}}),(_vm.errordata)?_c('p',{staticClass:"error_text"},[_vm._v(_vm._s(_vm.errordata))]):_vm._e(),_c('span',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"control"},[_c('button',{staticClass:"button primary",on:{"click":function($event){return _vm.AddEmail()}}},[_vm._v(" Add Email ")])])]),_c('hr'),_c('div',{staticClass:"email-group"},_vm._l((_vm.form.applied),function(e,index){return _c('div',{key:index,staticClass:"email"},[_c('p',[_vm._v(_vm._s(e))]),_c('i',{staticClass:"fas fa-trash",on:{"click":function($event){return _vm.DeleteEmail(index)}}})])}),0)]):_vm._e(),(_vm.applicationType === 'link')?_c('div',[(_vm.applicationType === 'link')?_c('div',{staticClass:"tab-header"},[_c('h3',[_vm._v("Please enter the link to your application")]),_c('p',[_vm._v(" Incase you already have your own recruitment system just use this link to redirect to your system ")])]):_vm._e(),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Enter your link")]),_c('p',{staticClass:"control has-icons-right"},[_c('ValidationProvider',{attrs:{"name":"Enter your link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.link),expression:"form.link"}],staticClass:"input",attrs:{"type":"text","placeholder":"Enter your link here","required":""},domProps:{"value":(_vm.form.link)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "link", $event.target.value)}}}),_c('span',{staticClass:"icon is-small is-right"},[_c('i',{staticClass:"fas fa-check-circle"})]),_c('span',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]):_vm._e()]),_c('div',{staticClass:"button-group"},[_c('button',{staticClass:"button info",on:{"click":function($event){_vm.addEmit(),
        _vm.$router.push({
          name: 'email',
          query: { id: _vm.$route.query.id, type: _vm.$route.query.type },
        })}}},[_vm._v(" Back ")]),(_vm.$route.query.id &&
        _vm.$route.query.type !== 'draft' &&
        _vm.$route.query.type !== 'expired'
        )?_c('button',{staticClass:"button primary",on:{"click":function($event){return handleSubmit(_vm.update)}}},[_vm._v(" Update ")]):_c('button',{staticClass:"button primary",on:{"click":function($event){return handleSubmit(_vm.updateDraft)}}},[_vm._v(" Save and Next ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
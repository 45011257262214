<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <div class="box">
        <ul class="option-tab">
          <li class="option-item" @click="applicationTypeEmail()" :class="{ 'in-use': applicationType === 'email' }">
            Email
          </li>

          <li class="option-item" @click="applicationTypeLink()" :class="{ 'in-use': applicationType === 'link' }">
            URL
          </li>

          <li class="option-item" @click="applicationTypeHide()" :class="{ 'in-use': applicationType === 'hide' }">
            None
          </li>
        </ul>
        <div v-if="applicationType === 'email'">
          <div class="tab-header">
            <h3>Email to receive application</h3>
            <p>Following email will receive the application from candidates.</p>
          </div>
          <div class="field has-addons is-fullwidth">
            <div class="control">
              <ValidationProvider name="Email" rules="email" v-slot="{ errors }">
                <input class="input" type="email" @keyup.enter="AddEmail()" v-model="application_email"
                  placeholder="Enter an email" />
                <p class="error_text" v-if="errordata">{{ errordata }}</p>
                <span style="color: red; font-size: 12px">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="control">
              <button class="button primary" @click="AddEmail()">
                Add Email
              </button>
            </div>
          </div>
          <hr />
          <div class="email-group">
            <div class="email" v-for="(e, index) in form.applied" :key="index">
              <p>{{ e }}</p>
              <i class="fas fa-trash" @click="DeleteEmail(index)"></i>
            </div>
          </div>
        </div>

        <div v-if="applicationType === 'link'">
          <div class="tab-header" v-if="applicationType === 'link'">
            <h3>Please enter the link to your application</h3>
            <p>
              Incase you already have your own recruitment system just use this
              link to redirect to your system
            </p>
          </div>
          <div class="field">
            <label for="" class="label">Enter your link</label>

            <p class="control has-icons-right">
              <ValidationProvider name="Enter your link" rules="required" v-slot="{ errors }">
                <input class="input" v-model="form.link" type="text" placeholder="Enter your link here" required />
                <span class="icon is-small is-right">
                  <i class="fas fa-check-circle"></i>
                </span>
                <span style="color: red; font-size: 12px">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>
          </div>
        </div>
      </div>
      <!-- Box -->
      <div class="button-group">
        <button class="button info" @click="
          addEmit(),
          $router.push({
            name: 'email',
            query: { id: $route.query.id, type: $route.query.type },
          })
          ">
          Back
        </button>
        <!-- <button class="button info" v-if="!$route.query.id" @click="addEmit(), $router.push({name: 'email', query: { id: $route.query.id} })">Back</button> -->
        <button v-if="$route.query.id &&
          $route.query.type !== 'draft' &&
          $route.query.type !== 'expired'
          " class="button primary" @click="handleSubmit(update)">
          Update
        </button>
        <button v-else class="button primary" @click="handleSubmit(updateDraft)">
          Save and Next
        </button>
        <!-- <button class="button primary" v-if="!$route.query.id" @click=" addEmit(), $router.push({name: 'upgrade',query: { id: $route.query.id}})">Save and Next</button> -->
        <!-- <button @click="addEmit()" class="button primary">Save and Next</button> -->
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { UPDATE_JOB, SAVE_DRAFT, UPDATE_DRAFT, GET_JOBID } from "@/graphql/Job";
import apolloClient_Company from "@/services/apollo-client-company";
export default {
  props: ["iget", "getEmail"],
  data: () => ({
    //  iget: null,
    input: false,
    applicationType: "email",
    application_email: [],
    errordata: "",
    link: "",

    form: {
      file: "",
      companyId: "",
      title: "",
      job: [""],
      location: [""],
      experience: "",
      jobLevelId: "",
      level: "",
      language: [""],
      salary: "",
      salaryid: true,
      description: "",
      tagIds: [],
      zone: [],
      coverletter: false,
      apply: false,
      uselink: false,
      link: "",
      applied: [],
      nortification: [],
      notifyExpire: [],
      ispublished: false,
      postdate: null,
      closedate: "",
      expiredate: "",
      details: "",
      staffQTY: "",
    },
  }),
  watch: {
    "$route.name": {
      async handler(val) {
        if (this.$route.query.id) {
          this.GetJobId();
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.iget) {
      this.form = this.iget;
    }
  },
  methods: {
    async update() {
      try {
        if (this.$route.query.id) {
          await apolloClient_Company.mutate({
            mutation: UPDATE_JOB,
            variables: {
              jobId: this.$route.query.id,
              employerId: this.form.companyId,
              title: this.form.title,
              description: this.form.description,
              jobFunctionId: this.form.job,
              workingLocationId: this.form.location,
              jobExperienceId: this.form.experience,
              jobLanguageId: this.form.language,
              jobEducationLevelId: this.form.level,
              salayRangeId: this.form.salary,
              isShowSalary: this.form.salaryid,
              tagIds: this.form.tagIds,
              jobZoneIds: [],
              jobLevelId: this.form.jobLevelId,
              openingDate: this.form.postdate,
              closingDate: this.form.closedate,
              expiredDate: this.form.expiredate,
              isApplyUrl: false, // this.form.uselink,
              isCoverLetter: false, //this.form.coverletter,
              isNotApply: this.form.apply,
              isHideApplyButton: this.form.apply,
              appliedEmails: this.form.applied,
              nortificationEmails: this.form.nortification,
              notifyExpire: this.form.notifyExpire,
              isPublished: true, //this.form.ispublished,
              image: "", //this.form.file,
              isURL: this.form.link,
              details: "", //this.form.details,
              staffQTY: this.form.staffQTY,
            },
          });
          this.$emit("putdata", this.form);
          if (this.$route.query.id) {
            this.$router.push({
              name: "summary",
              query: { id: this.$route.query.id, type: this.$route.query.type },
            });
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    applicationTypeHide() {
      (this.form.apply = true), (this.applicationType = "hide");
      this.form.link = "";
      this.form.applied = [];
    },
    applicationTypeEmail() {
      (this.form.apply = false), (this.applicationType = "email");
      this.form.link = "";
      if (this.getEmail) {
        this.form.applied = this.getEmail.appliedEmails;
      }
    },
    applicationTypeLink() {
      (this.form.apply = false), (this.applicationType = "link");
      this.form.applied = [];
    },
    addEmit() {
      this.$emit("putdata", this.form);
    },
    async updateDraft() {
      await apolloClient_Company.query({
        query: UPDATE_DRAFT,
        variables: {
          _id: this.$route.query.id,
          title: this.form.title,
          description: this.form.description,
          jobFunctionId: this.form.job,
          workingLocationId: this.form.location,
          jobExperienceId: this.form.experience,
          jobLanguageId: this.form.language,
          jobEducationLevelId: this.form.level,
          salayRangeId: this.form.salary,
          isShowSalary: this.form.salaryid,
          tagIds: this.form.tagIds,
          jobZoneIds: null, //this.form.zone,
          jobLevelId: this.form.jobLevelId,
          openingDate: this.form.postdate,
          closingDate: this.form.closedate,
          expiredDate: this.form.expiredate,
          isApplyUrl: false, // this.form.uselink,
          isCoverLetter: false, //this.form.coverletter,
          isNotApply: this.form.apply,
          isHideApplyButton: "", //this.form.apply,
          appliedEmails: this.form.applied,
          nortificationEmails: this.form.nortification,
          notifyExpire: this.form.notifyExpire,
          isPublished: true,
          image: "", //this.form.file,
          isURL: this.form.link,
          details: "", //this.form.details,
          staffQTY: this.form.staffQTY,
        },
      });
      this.$emit("putdata", this.form);
      if (this.$route.query.id) {
        this.$router.push({
          name: "upgrade",
          query: { id: this.$route.query.id, type: this.$route.query.type },
        });
      } else {
        this.$router.push({ name: "upgrade" });
      }
    },
    async GetJobId() {
      const res = await apolloClient_Company.query({
        query: GET_JOBID,
        variables: {
          type: this.$route.query.type,
          jobId: this.$route.query.id,
        },
      });
      this.isLoading = false;
      this.getjobId = res.data.editJob;
      this.form.job = this.getjobId.jobFunctionId.map((i) => i._id);
      this.form.language = this.getjobId.jobLanguageId.map((i) => i._id);
      this.form.location = this.getjobId.workingLocationId.map((i) => i._id);
      this.form.experience = this.getjobId.jobExperienceId._id;
      this.form.tagIds =
        this.getjobId.tagIds !== null
          ? this.getjobId.tagIds.map((i) => i._id)
          : null;
      this.form.jobLevelId = this.getjobId.jobLevelId._id
      this.form.level = this.getjobId.jobEducationLevelId._id;
      this.form.salary = this.getjobId.salayRangeId._id;
      this.form.title = this.getjobId.title;
      this.form.apply = this.getjobId.isNotApply;
      this.form.link = this.getjobId.isURL;
      this.form.description = this.getjobId.description;
      this.form.applied = this.getjobId.appliedEmails;
      this.form.nortification = this.getjobId.nortificationEmails;
      this.form.notifyExpire = this.getjobId.notifyExpire;
      this.form.postdate = new Date(this.getjobId.openingDate);
      this.form.closedate = new Date(this.getjobId.closingDate);
      this.form.expiredate = new Date(this.getjobId.expiredDate);
      this.form.companyId = this.getjobId.employerId._id;
      this.form.staffQTY = this.getjobId.staffQTY;
      this.form.salaryid = this.getjobId.isShowSalary;
      if (this.form.applied == "") {
        //this.form = this.getEmail
        this.form.applied = this.getEmail.appliedEmails;
      }
      if (this.form.link) {
        this.applicationType = "link";
      }
      if (this.form.apply == true) {
        this.applicationType = "hide";
      }
    },
    AddEmail() {
      if (this.application_email.length > 1) {
        this.form.applied.push(this.application_email);
        this.application_email = "";
      } else {
        this.errordata = "Please enter email";
        //this.application_email = ''
      }
    },
    DeleteEmail(index) {
      this.form.applied.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.error_text {
  color: red;
}

.option-tab {
  display: flex;
  // justify-content: center;
  margin-bottom: 20px;

  .option-item {
    background-color: var(--light-grey-color);
    cursor: pointer;
    transition: all ease-in-out 0.15s;

    &:hover {
      background-color: var(--primary-color);
      color: #fff;
    }

    &:nth-child(1) {
      border-radius: 5px 0 0 5px;
      padding: 8px 15px 8px 20px;
    }

    &:nth-child(2) {
      border-left: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      border-radius: 0;
      padding: 8px 15px 8px 20px;
    }

    &:nth-child(3) {
      border-radius: 0 5px 5px 0;
      padding: 8px 20px 8px 15px;
    }

    &.in-use {
      background-color: rgba(var(--primary-color-rgb), 0.15);
      color: var(--primary-color);
      box-shadow: 0 0 0px 1px rgba(var(--primary-color-rgb), 1);
      z-index: 2;
    }
  }
}

.tab-header {
  margin-bottom: 15px;

  h3 {
    margin: 0;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700;
    color: var(--primary-color);
  }

  p {
    color: var(--text-color);
    margin: 0;
    font-size: 16px;
  }
}

.control {
  width: 100%;
}

hr {
  height: 1px;
}

.email-group {
  display: flex;
  align-items: center;
  color: var(--text-color);
  flex-wrap: wrap;

  .email {
    margin-right: 5px;
    margin-bottom: 5px;
    display: flex;
    position: relative;
    font-size: var(--md-font);
    background-color: var(--light-grey-color);
    border: 1px solid var(--border-color);

    p {
      padding-bottom: calc(0.5em - 1px);
      padding-left: 1em;
      padding-right: 1em;
      padding-top: calc(0.5em - 1px);
    }

    i {
      background-color: rgba(var(--alert-color-rgb), 0.08);
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: var(--md-font);
      transition: all ease-in-out 0.2s;
      color: var(--alert-color);

      &:hover {
        background-color: var(--alert-color);
        color: #fff;
      }
    }
  }
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
